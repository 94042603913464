<template>
  <div>
    <v-row dense>
      <v-col cols="10">
        <v-text-field
          label="Názov"
          v-model="item.title"
        ></v-text-field>
      </v-col>
      <v-col md="2" cols="4">
        <v-text-field
          label="Poradie"
          v-model="item.order"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-file-input
          v-if="!create"
          v-model="image_file"
          label="Obrázok"
        ></v-file-input>
        <v-file-input
          v-else
          v-model="item.image_file"
          label="Obrázok"
        ></v-file-input>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-img
          :src="item.image"
          style="cursor: pointer;"
          @click="overlay = true"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="right">
        <v-btn
          color="error"
          class="mr-5"
          small
          @click="remove()"
        >Zmazať</v-btn>
        <v-btn
          v-if="!create"
          color="primary"
          small
          @click="save()"
        >Uložiť</v-btn>
      </v-col>
    </v-row>

    <v-overlay v-show="overlay">
      <v-img
        :src="item.image"
        contain
        style="cursor: pointer;"
        @click="overlay = false"
      ></v-img>
    </v-overlay>
  </div>
</template>
        
  <script>
import Vue from "vue";
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();

Vue.use(require("vue-moment"));

export default {
  props: {
    item: { type: Object, required: true },
    create: { type: Boolean, required: false, default: false },
    deleteCommentAttachmentFunc: { type: Function, required: true },
    updateCommentAttachmentFormDataFunc: { type: Function, required: true },
    createCommentAttachmentFormDataFunc: { type: Function, required: true },
  },

  data() {
    return {
      overlay: false,
      image_file: null
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
    remove() {
      this.deleteCommentAttachmentFunc(this.item.id).then(() => {
        this.$emit("sync-child");
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
    },

    save() {
      let formData = new FormData();

      for (var key in this.item) {
        if (key == "image") continue;

        if (this.item[key] != null) formData.set(key, this.item[key]);
      }

      console.log(formData);


      if (this.image_file instanceof File)
        formData.append("image", this.image_file);

      if (this.item.id) {
        this.updateCommentAttachmentFormDataFunc(this.item.id, formData).then(response => {
          this.item = response;
          this.$emit("sync-child");
        });
      }
      else {
        this.createCommentAttachmentFormDataFunc(formData).then(response => {
          this.item.id = response.id
          this.$emit("sync-child");
        });
      }
    }
  },
};
  </script>