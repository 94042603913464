<template>
  <div>
    <v-row class="pt-1 mt-5">
      <v-col cols="12">
        <strong>{{ item.title }}</strong>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <v-col cols="12">
        <youtube-component :url="item.video"></youtube-component>
      </v-col>
    </v-row>
  </div>
</template>
        
  <script>
import Vue from "vue";
import YoutubeComponent from '@/components/YoutubeComponent.vue';

Vue.use(require("vue-moment"));

export default {
  props: {
    item: { type: Object, required: true },
  },

  components: {
    YoutubeComponent
  },

  data() {
    return {
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
  },
};
  </script>