<template>
  <v-container class="ma-0 pa-0">
    <v-row justify="center">
      <v-col md="1" v-show="!$vuetify.breakpoint.smAndDown">
        <v-btn v-if="question.active_rec" elevation="2" fab small @click="$router.go(-1)"><v-icon
            color="primary">mdi-arrow-left-bold</v-icon></v-btn>
      </v-col>
      <v-col cols="12" md="11" class="d-flex justify-center align-center text-h6">
        <span v-if="poll">Diskusia k téme: <span class="primary--text" style="cursor: pointer;" @click="$router.go(-1)">{{
          poll.short_title }}</span> - {{ question.short_title }}</span>

        <span v-else>Diskusia k téme: <span class="primary--text" style="cursor: pointer;" @click="$router.go(-1)">{{
          question.short_title }}</span></span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-if="question.active_rec" cols="12">
        <fake-button small color="primary" create @click="newCommentDialog(newComment, null)">Pridať môj nový
          dôvod</fake-button>
      </v-col> </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="4" @click="choose = 'YES'">
        <v-card>
          <v-card-title class="text-body-1 font-weight-bold primary--text" :style="getStyle('YES')">HLASUJEM ÁNO ({{
            count.YES }})</v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" @click="choose = 'NO'">
        <v-card>
          <v-card-title class="text-body-1 font-weight-bold primary--text" :style="getStyle('NO')">HLASUJEM NIE ({{
            count.NO }})</v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" @click="choose = 'ABSTAIN'">
        <v-card>
          <v-card-title class="text-body-1 font-weight-bold primary--text" :style="getStyle('ABSTAIN')">ZDRŽÍM sa ({{
            count.ABSTAIN }})</v-card-title>
        </v-card>

      </v-col>

    </v-row>

    <v-alert v-if="!question.active_rec" color="info" dark>Nachádzate sa v archíve diskusie</v-alert>
    <v-row dense>
      <v-col cols="12">
        <!-- TODO -->
        <poll-discussion-type v-show="choose == 'YES'" title="Dôvody, prečo HLASUJEM za ÁNO" type="YES"
          :questionID="questionID" :surveyID="surveyID" :partyID="partyID" :sync="sync_yes"
          :getCommentsFunc="getCommentsFunc" :updateCommentFunc="updateCommentFunc" :deleteCommentFunc="deleteCommentFunc"
          :voteUpCommentFunc="voteUpCommentFunc" :voteDownCommentFunc="voteDownCommentFunc"
          :readCommentFunc="readCommentFunc" :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
          :createCommentAttachmentFunc="createCommentAttachmentFunc"
          :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
          :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
          :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc" :question="question"
          :createCommentFunc="createCommentFunc" @sync-child="syncChild">
        </poll-discussion-type>
        <poll-discussion-type v-show="choose == 'NO'" title="Dôvody, prečo HLASUJEM za NIE" type="NO"
          :questionID="questionID" :sync="sync_no" :surveyID="surveyID" :partyID="partyID"
          :getCommentsFunc="getCommentsFunc" :updateCommentFunc="updateCommentFunc" :deleteCommentFunc="deleteCommentFunc"
          :voteUpCommentFunc="voteUpCommentFunc" :voteDownCommentFunc="voteDownCommentFunc"
          :readCommentFunc="readCommentFunc" :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
          :createCommentAttachmentFunc="createCommentAttachmentFunc"
          :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
          :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
          :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc" :question="question"
          :createCommentFunc="createCommentFunc" @sync-child="syncChild">
        </poll-discussion-type>
        <poll-discussion-type v-show="choose == 'ABSTAIN'" title="Dôvody, prečo sa ZDRŽÍM HLASOVANIA" type="ABSTAIN"
          :questionID="questionID" :surveyID="surveyID" :partyID="partyID" :sync="sync_abstain"
          :getCommentsFunc="getCommentsFunc" :updateCommentFunc="updateCommentFunc" :deleteCommentFunc="deleteCommentFunc"
          :voteUpCommentFunc="voteUpCommentFunc" :voteDownCommentFunc="voteDownCommentFunc"
          :readCommentFunc="readCommentFunc" :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
          :createCommentAttachmentFunc="createCommentAttachmentFunc"
          :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
          :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
          :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc" :question="question"
          :createCommentFunc="createCommentFunc" @sync-child="syncChild">
        </poll-discussion-type>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Môj nový dôvod</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              light
              color="success"
              rounded
              class="pa-5"
              @click="createComment(newComment)"
              :disabled="!user.photo"
            >Odoslať môj dôvod</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

        <v-alert
          dark
          v-if="!user.photo"
          color="warning"
        >Nemáš pridanú fotografiu, preto nemôžeš pridávať príspevky do diskusie. <router-link :to="{ name: 'Profil', params: { tabID: 1 }}">Pridaj si najprv fotografiu v profile.</router-link></v-alert>

        <v-list
          v-else
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-select
                  label="Sem pridaj typ"
                  v-model="newComment.type"
                  :items="[{text: 'ÁNO', value: 'YES'}, {text: 'NIE', value: 'NO'}, {text: 'ZDRŽANIE SA', value: 'ABSTAIN'}]"
                ></v-select>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Znenie komentáru</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  label="Sem pridaj znenie tvojho komentáru"
                  rows="1"
                  auto-grow
                  v-model="newComment.text"
                ></v-textarea>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Odkazy</v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col><v-btn
                      color="primary"
                      small
                      @click="addAttachment('TEXT')"
                    >
                      Pridať
                    </v-btn></v-col>
                </v-row>

                <v-card
                  class="ma-1"
                  v-for="item in getAttachmentsType(newComment.attachments, 'TEXT')"
                  v-bind:key="item.id"
                  outlined
                >
                  <v-card-text>

                    <poll-discussion-source-text-edit
                      :item="item"
                      create
                      :createCommentAttachmentFunc="createCommentAttachmentFunc"
                      :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
                      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
                      @sync-child="getComments()"
                    ></poll-discussion-source-text-edit>
                  </v-card-text>

                </v-card>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Obrázky</v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col><v-btn
                      color="primary"
                      small
                      @click="addAttachment('IMAGE')"
                    >
                      Pridať
                    </v-btn></v-col>
                </v-row>
                <v-card
                  class="ma-1"
                  v-for="item in getAttachmentsType(newComment.attachments, 'IMAGE')"
                  v-bind:key="item.id"
                  outlined
                >
                  <v-card-text>
                    <poll-discussion-source-image-edit
                      :item="item"
                      create
                      :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
                      :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
                      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
                      @sync-child="getComments()"
                    ></poll-discussion-source-image-edit>
                  </v-card-text>
                </v-card>
              </v-list-item-subtitle>
            </v-list-item-content></v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Videá</v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col><v-btn
                      color="primary"
                      small
                      @click="addAttachment('VIDEO')"
                    >
                      Pridať
                    </v-btn></v-col>
                </v-row>

                <v-card
                  class="ma-1"
                  v-for="item in getAttachmentsType(newComment.attachments,'VIDEO')"
                  v-bind:key="item.id"
                  outlined
                >
                  <v-card-text>
                    <poll-discussion-source-video-edit
                      :item="item"
                      create
                      :createCommentAttachmentFunc="createCommentAttachmentFunc"
                      :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
                      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
                      @sync-child="getComments()"
                    ></poll-discussion-source-video-edit>
                  </v-card-text>
                </v-card>
              </v-list-item-subtitle></v-list-item-content></v-list-item>
        </v-list>

      </v-card>
    </v-dialog>


  </v-container>
</template>
  
<script>
import Vue from "vue";

import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";

import PollDiscussionType from '@/components/poll/discussion/PollDiscussionType.vue';
import PollDiscussionSourceTextEdit from './PollDiscussionSourceTextEdit.vue';
import PollDiscussionSourceImageEdit from './PollDiscussionSourceImageEdit.vue';
import PollDiscussionSourceVideoEdit from './PollDiscussionSourceVideoEdit.vue';

import FakeButton from '../../FakeButton.vue';

const apiService = new APIService();

Vue.use(require("vue-moment"));

export default {
  name: "Discussion",
  components: {
    PollDiscussionSourceTextEdit,
    PollDiscussionSourceImageEdit,
    PollDiscussionSourceVideoEdit,
    FakeButton,
    PollDiscussionType,
  },

  props: {
    questionID: { type: Number, required: false },
    surveyID: { type: Number, required: false },
    partyID: { type: Number, required: false },

    getQuestionFunc: { type: Function, required: true },
    getPollFunc: { type: Function, required: true },

    getCommentsFunc: { type: Function, required: true },
    createCommentFunc: { type: Function, required: true },
    updateCommentFunc: { type: Function, required: true },
    deleteCommentFunc: { type: Function, required: true },
    voteUpCommentFunc: { type: Function, required: true },
    voteDownCommentFunc: { type: Function, required: true },
    readCommentFunc: { type: Function, required: true },
    createCommentAttachmentFormDataFunc: { type: Function, required: true },
    createCommentAttachmentFunc: { type: Function, required: true },
    updateCommentAttachmentFormDataFunc: { type: Function, required: true },
    updateCommentAttachmentFunc: { type: Function, required: true },
    deleteCommentAttachmentFunc: { type: Function, required: true },
  },

  data: () => ({
    sync_abstain: true,
    sync_no: true,
    sync_yes: true,

    loading: false,
    dialog: false,
    user: {},
    question: {},
    poll: null,

    choose: 'YES',

    count: {
      YES: 0,
      NO: 0,
      ABSTAIN: 0
    },

    newComment: {
      id: null,
      text: "",
      type: "",
      parent: "",
      parent_survey: "",
      parent_party: "",
      reaction_to: null,

      attachments: []
    },
  }),

  mounted: function () {
    this.getMe();
    this.getQuestion();

    this.getCommentsAll();
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender"
    ]),

    getMobileClasses() {
      if (this.$vuetify.breakpoint.smAndDown)
        return "d-flex justify-center align-center";
      else
        return ""
    },
  },

  methods: {
    newCommentDialog(item, reaction_to) {
      this.newComment.reaction_to = reaction_to;

      this.newComment.parent = item.parent;
      this.newComment.parent_survey = item.parent_survey;
      this.newComment.parent_party = item.parent_party;

      this.newComment.type = item.type;
      this.newComment.text = item.text;
      this.newComment.id = item.id;
      this.newComment.attachments = item.attachments;

      this.dialog = true;
    },

    createComment(comment) {
      if (this.questionID)
        this.newComment.parent = this.questionID;
      else {
        this.newComment.parent_survey = this.surveyID;
        this.newComment.parent_party = this.partyID;
      }

      this.createCommentFunc(comment).then(response => {
        let comment_id = response.id;

        comment.attachments.forEach(async (item) => {
          if (item.deleted)
            return;

          let formData = new FormData();

          for (var key in item) {
            if (key == "image") continue;

            if (item[key] != null) formData.set(key, item[key]);
          }

          if (item.image_file instanceof File)
            formData.append("image", item.image_file);

          formData.set("comment", comment_id);

          await this.createCommentAttachmentFormDataFunc(formData);
        });

        this.syncComments();

        this.newComment.id = null;
        this.newComment.type = "";
        this.newComment.text = "";
        this.newComment.attachments = [];
        this.dialog = false;
      })
    },

    addAttachment(type) {
      this.newComment.attachments.push({
        id: null,
        type: type,
        video: null,
        image: null,
        title: null,
        text: null,
        comment: null,
        image_file: null,
        deleted: false
      })
    },

    getAttachmentsType(attachments, type) {
      return attachments.filter(function (obj) {
        return obj.type == type && !obj.deleted;
      });
    },

    getComments(type) {
      let limit = 0;
      let offset = 0;
      let filters = [];
      if (this.questionID)
        filters = [['parent', this.questionID], ['type', type]];
      else
        filters = [['parent_survey', this.surveyID], ['parent_party', this.partyID], ['type', type]];


      this.getCommentsFunc("", "", limit, offset, filters).then((response) => {
        this.count[type] = response.count;
      });
    },

    getStyle(type) {
      if (this.choose == type) {
        if (type == 'YES')
          return "background-color: #aecbae; cursor: pointer; word-break: normal !important; overflow-wrap: anywhere !important;"
        if (type == 'NO')
          return "background-color: #faa7a2; cursor: pointer; word-break: normal !important; overflow-wrap: anywhere !important;"
        if (type == 'ABSTAIN')
          return "background-color: #d5d5d5; cursor: pointer; word-break: normal !important; overflow-wrap: anywhere !important;"
      }

      return "cursor: pointer; word-break: normal !important; overflow-wrap: anywhere !important;";
    },

    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
      });
    },

    getPoll(id) {
      this.getPollFunc(id).then((response) => {
        this.poll = response;
      });
    },

    getQuestion() {
      if (this.questionID)
        this.getQuestionFunc(this.questionID).then((response) => {
          this.question = response;
          if (this.question.poll)
            this.getPoll(this.question.poll);
        });
      else {
        this.getQuestionFunc(this.partyID).then((response) => {
          this.question = response;
          if (this.surveyID)
            this.getPoll(this.surveyID);
        });
      }
    },

    getCommentsAll() {
      this.getComments('YES');
      this.getComments('NO');
      this.getComments('ABSTAIN');
    },

    syncChild() {
      this.getCommentsAll();
    },

    syncComments() {
      this.sync_yes = !this.sync_yes;
      this.sync_no = !this.sync_no;
      this.sync_abstain = !this.sync_abstain;
      this.getCommentsAll();
    },
  },
};
</script>